import {
  request,
  noTimeOutReq
} from '@/utils/request.js'
//查询考试成绩统计过滤条件
export function getScoreCensus(examId) {
  return request({
    url: `/exam/achievement/statistics/get/condition/${examId}`,
    method: 'post',
  })
}

//查询考试成绩统计列表
export function getScoreList(examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/achievement/statistics/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

//查询考试设定阈值

export function getThreshold(examId, subjectType) {
  return request({
    url: `/exam/achievement/statistics/get/threshold/${examId}/${subjectType}`,
    method: 'post',
  })
}

//查询考试成绩分布统计信息
export function getScoreSpread(examId, data) {
  return request({
    url: `/exam/achievement/statistics/get/distribution/${examId}`,
    method: 'post',
    data
  })
}