<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="总体" name="1"></el-tab-pane>
      <el-tab-pane label="按区域" name="2"></el-tab-pane>
      <el-tab-pane label="按学校" name="3"></el-tab-pane>
      <el-tab-pane label="按班级" name="4"></el-tab-pane>
    </el-tabs>
    <div class="title">{{ examName }}</div>
    <el-select
      v-if="isAreaSelect"
      v-model="areaValue"
      @change="changeAreaSection"
      filterable
      placeholder="全部"
    >
      <el-option
        v-for="item in areaOptions"
        :key="item.sysOrgAreaId"
        :label="item.sysOrgAreaName"
        :value="item.sysOrgAreaId"
      ></el-option>
    </el-select>
    <el-select
      v-if="isSchoolSelect"
      v-model="schoolValue"
      @change="changeSchoolSection"
      filterable
      placeholder="全部"
    >
      <el-option
        v-for="item in schoolOptions"
        :key="item.sysOrgSchoolId"
        :label="item.sysOrgSchoolName"
        :value="item.sysOrgSchoolId"
      ></el-option>
    </el-select>
    <el-select
      v-if="isClassSelect"
      v-model="classValue"
      @change="changeSchoolClassSection"
      filterable
      placeholder="全部"
    >
      <el-option
        v-for="item in classOptions"
        :key="item.sysOrgSchoolClassId"
        :label="item.sysOrgSchoolClassName"
        :value="item.sysOrgSchoolClassId"
      ></el-option>
    </el-select>
    <!-- background:'#f5f7fa', -->
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 30px"
      :border="true"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      v-loading="loadingTable"
    >
      <el-table-column
        v-if="isSysOrgAreaShow"
        prop="sysOrgAreaName"
        label="区域"
        width="180"
      ></el-table-column>
      <el-table-column
        v-if="isSysOrgSchoolShow"
        prop="sysOrgSchoolName"
        label="学校"
        width="180"
      ></el-table-column>
      <el-table-column
        v-if="isSysOrgSchoolClassShow"
        prop="sysOrgSchoolClassName"
        label="班级"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="lowScoreCount"
        :label="`低分人数（0~` + this.scoreThreshold.lowScoreThreshold + `分）`"
        width="180"
      ></el-table-column>
      <el-table-column prop="lowScorePercent" label="低分率" width="180">
        <template slot-scope="scope">
          {{ scope.row.lowScorePercent + '%' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="passCount"
        :label="`及格人数（≥` + this.scoreThreshold.passScore + `分）`"
      ></el-table-column>
      <el-table-column prop="passPercent" label="及格率">
        <template slot-scope="scope">
          {{ scope.row.passPercent + '%' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="excellentScoreCount"
        :label="
          `优分人数（≥` + this.scoreThreshold.excellentScoreThreshold + `分)`
        "
      ></el-table-column>
      <el-table-column prop="excellentScorePercent" label="优分率">
        <template slot-scope="scope">
          {{ scope.row.excellentScorePercent + '%' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="highScoreCount"
        :label="`高分人数（≥` + this.scoreThreshold.highScoreThreshold + `分）`"
      ></el-table-column>
      <el-table-column prop="highScorePercent" label="高分率">
        <template slot-scope="scope">
          {{ scope.row.highScorePercent + '%' }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
    ></el-pagination>
  </div>
</template>
<script>
import {
  getScoreCensus,
  getScoreList,
  getThreshold,
} from '@/api/score/scoreCensus.js'
export default {
  data() {
    return {
      tableData: [],
      //控制选择框
      isAreaSelect: false,
      isSchoolSelect: false,
      isClassSelect: false,
      //头部标签页
      activeName: '1',
      //列表区域栏
      isSysOrgAreaShow: false,
      isSysOrgSchoolShow: false,
      isSysOrgSchoolClassShow: false,
      //下拉框选值
      areaValue: '',
      schoolValue: '',
      classValue: '',
      //下拉框数组
      areaOptions: [],
      schoolOptions: [],
      classOptions: [],
      //路由拿到的数据
      examId: '',
      subjectType: '',
      //分页
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      //选项卡控制变量
      tabName: 1,
      //分数阈值
      scoreThreshold: {},
      //请求参数
      scoreCensusParam: {
        orgSysAreaId: '',
        orgSysOrgSchoolId: '',
        orgSysOrgSchoolClassId: '',
        subjectType: '',
        type: 1,
      },
      examName: '',

      loadingTable: false,
    }
  },
  mounted() {
    this.examId = Number(this.$route.query.examId)
    this.subjectType = this.$route.query.subject
    this.scoreCensusParam.subjectType = this.subjectType
    this.examName = this.$route.query.examName
    this.getExamScoreThreshold()
    this.getScoreCensusList()
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      console.log(`每页 ${val} 条`)
      this.handleClick(this.tabName)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.handleClick(this.tabName)
    },
    //切换选项卡
    handleClick(tab, event) {
      // 清除请求条件
      this.areaValue = ''
      this.schoolValue = ''
      this.classValue = ''
      this.scoreCensusParam.orgSysAreaId = ''
      this.scoreCensusParam.orgSysOrgSchoolId = ''
      this.scoreCensusParam.orgSysOrgSchoolClassId = ''
      this.classOptions = []

      this.tabName = tab

      this.loadingTable = true
      if (tab.name == 1) {
        this.isSysOrgAreaShow = false
        this.isSysOrgSchoolShow = false
        this.isSysOrgSchoolClassShow = false
        this.isAreaSelect = false
        this.isSchoolSelect = false
        this.isClassSelect = false
        this.getScoreCensusList()
      }
      if (tab.name == 2) {
        getScoreCensus(this.examId).then((res) => {
          this.loadingTable = false
          let dataList = res
          this.isSysOrgAreaShow = true
          this.isSysOrgSchoolShow = false
          this.isSysOrgSchoolClassShow = false
          this.isAreaSelect = true
          this.isSchoolSelect = false
          this.isClassSelect = false
          //赋值给选择下拉框并且默认选中第一个
          this.areaOptions = dataList.data.sysOrgAreaList
          this.scoreCensusParam.subjectType = this.subjectType
          this.scoreCensusParam.type = 2
          getScoreList(
            this.examId,
            this.currentPage,
            this.pageSize,
            this.scoreCensusParam
          ).then((res) => {
            let { data } = res
            this.tableData = data
            this.pageTotal = res.total
          })
        })
      }
      if (tab.name == 3) {
        getScoreCensus(this.examId).then((res) => {
          this.loadingTable = false
          let dataList = res
          this.isSysOrgAreaShow = false
          this.isSysOrgSchoolShow = true
          this.isSysOrgSchoolClassShow = false
          this.isAreaSelect = false
          this.isSchoolSelect = true
          this.isClassSelect = false
          //赋值给选择下拉框并且默认选中第一个
          this.schoolOptions = dataList.data.sysOrgSchoolList
          this.scoreCensusParam.subjectType = this.subjectType
          this.scoreCensusParam.type = 3
          getScoreList(
            this.examId,
            this.currentPage,
            this.pageSize,
            this.scoreCensusParam
          ).then((res) => {
            let { data } = res
            this.tableData = data
            this.pageTotal = res.total
          })
        })
      }
      if (tab.name == 4) {
        getScoreCensus(this.examId).then((res) => {
          this.loadingTable = false
          let dataList = res
          this.isSysOrgAreaShow = false
          this.isSysOrgSchoolShow = false
          this.isSysOrgSchoolClassShow = true
          this.isAreaSelect = false
          this.isSchoolSelect = true
          this.isClassSelect = true
          //赋值给选择下拉框并且默认选中第一个
          this.schoolOptions = dataList.data.sysOrgSchoolList
          ;(this.scoreCensusParam.subjectType = this.subjectType),
            (this.scoreCensusParam.type = 4),
            getScoreList(
              this.examId,
              this.currentPage,
              this.pageSize,
              this.scoreCensusParam
            ).then((res) => {
              let { data } = res
              this.tableData = data
              this.pageTotal = res.total
            })
        })
      }
    },
    //获取分数列表
    getScoreCensusList() {
      for (let key in this.scoreCensusParam) {
        if (
          this.scoreCensusParam[key] === 0 ||
          this.scoreCensusParam[key] === '' ||
          this.scoreCensusParam[key] === undefined
        ) {
          delete this.scoreCensusParam[key]
        }
      }
      getScoreList(
        this.examId,
        this.currentPage,
        this.pageSize,
        this.scoreCensusParam
      ).then((res) => {
        this.loadingTable = false
        let { data } = res
        this.tableData = data
        this.pageTotal = res.total
      })
    },
    getExamScoreThreshold() {
      this.loadingTable = true
      getThreshold(this.examId, this.subjectType).then((res) => {
        this.loadingTable = false
        if (res.success) {
          this.scoreThreshold = res.data
          console.log(res.data)
        } else {
          this.$message(data.msg)
        }
      })
    },
    //选择区域
    changeAreaSection() {
      this.scoreCensusParam.orgSysAreaId = this.areaValue
      this.getScoreCensusList()
    },
    //选择学校
    changeSchoolSection() {
      this.scoreCensusParam.orgSysOrgSchoolId = this.schoolValue
      this.schoolOptions.forEach((v) => {
        if (v.sysOrgSchoolId == this.schoolValue) {
          this.classOptions = v.sysOrgSchoolClassList
        }
      })
      this.classValue = ''
      this.getScoreCensusList()
    },
    //选择班级
    changeSchoolClassSection() {
      this.scoreCensusParam.orgSysOrgSchoolId = this.schoolValue
      this.scoreCensusParam.orgSysOrgSchoolClassId = this.classValue
      this.getScoreCensusList()
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
